body {
  background-color: #ececec;
}
.google-map {
  border-radius: 15px;
  border: 2px solid gray;
  position: relative;
  flex-wrap: wrap;
  min-width: 60%;

  height: 90% !important;
}
.google-map-container {
  position: relative;
  display: flex;
  margin: 10px;
  margin-top: 20px;
  width: inherit !important;
  justify-content: flex-start;
  height: 100% !important;
}

@media only screen and(max-width: 800px) {
  .google-map {
    height: 900px !important;
  }
  .google-map-container {
    position: relative;
    display: flex;
    margin: 10px;
    margin-top: 20px;
    width: inherit !important;
    justify-content: flex-start;
    height: 100vh !important;
  }
}
