.footer {
  width: 100%;
  height: 200px;
  z-index: 100;
  overflow: hidden;
  position: relative;
  background-color: lightgray;
  border-radius: 20% 20% 0% 0%;
  box-shadow: inset 0px 16px 16px -8px rgba(0, 0, 0, 0.35);
}
.right-footer-links {
  width: 100% !important;
}
.ffb {
  height: 58px;
}
.fig {
  height: 70px;
  padding-bottom: 13px;
}
