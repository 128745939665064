.side-drawer {
  height: 100%;
  background-color: #23272a;
  box-shadow: -2px 0px 5px rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  width: 70% !important;
  max-width: 400px;
  z-index: 2000;
  transform: translateX(-100%);
  transition: transform 0.3s ease-out;
}

.side-drawer ul {
  padding: 0;

  height: 100%;
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 20px;
  align-items: center;
}
.side-drawer li {
  display: block !important;
  color: white;
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
  display: flex;
  justify-content: center !important;
  flex-direction: row;
  width: 100%;
  height: 30px;
  padding-top: 10px !important;
  padding-bottom: 40px !important;
}
.side-drawer li:active {
  color: lightgray;
}
.side-drawer a {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  justify-content: center !important;
  width: inherit;
  height: inherit;
  cursor: pointer;
}
.side-drawer a:hover {
  color: white;
}
.side-drawer a:active {
  color: lightgray;
}
.side-drawer.open {
  transform: translateX(0);
}
.sideD-dropmenu li {
  z-index: 30000;
  margin-bottom: 2px !important;
  border: none !important;
}
